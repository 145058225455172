import get from 'lodash/get';
import find from 'lodash/find';
import { css } from 'emotion';
import { lookupStore } from '../store/lookup-store';
import insyncLogo from '../assets/insyncLogo.svg';
import mobiruLogo from '../assets/mobiruLogo.png';
import pbisLogo from '../assets/PBISLogo.svg';
import cmtLogo from '../assets/cmtLogo.png';
import faradayLogo from '../assets/faradayLogo.png';
import coveaLogo from '../assets/coveaLogo.png';
import insyncHeaderLogo from '../assets/insyncLogoSecundary.png';
import pbisHeaderLogo from '../assets/pbis-mini-logo.png';

export const CUSTOMER_PORTAL = 'customerPortal';
export const MOBIRU = 'Mobiru';
export const MOBIRU_BUSINESS = 'Mobiru_Business';
export const COVERMYTOOLS = 'CovermytoolsRevised';
export const COVERMYTOOLSINSURANCE = 'CovermytoolsInsurance';
export const BEAUTY = 'InsyncBeautyRevised';
export const AXIS_CLINICAL = 'AXISClinical';
export const AXIS_AESTHETIC = 'AXISAesthetic';
export const PBIS = 'PBISParkLeisureHome';
export const BEAZLEYAESTHETICS = 'BeazleyAesthetics';
export const COVEATRADESMAN = 'CoveaTradesmanRevisedScheme';
export const AXAPI = 'AXAProfessionalPI';
export const FARADAYLIABILITY = 'FaradayLiability';
export const AXIS_PROPERTY_OWNERS = 'AXISPropertyOwners';
export const ALTEA = 'STAC';
export const AXISPI = 'AXISCombined';
export const AXISSPI = 'AXISProfessionalsCombined';
const mobiruDevicesCovered = quoteState => {
  quoteState.genericInsuredObject.map(object => {
    const item = get(object, 'dataPointData');
    if (item) {
      const device = find(lookupStore.devices, element => element.code === get(item, 'DeviceType.value'));
      const model = find(lookupStore.deviceModels, element => element.code === get(item, 'MobileModel.value'));

      const modelValue =
        get(model, 'code') === 'OTHERMODEL'
          ? get(item, 'MobileModelOther')
          : get(model, 'value', get(item, 'MobileModel.value'));

      const make = find(
        get(lookupStore.makesByDevice, get(device, 'code')),
        element => element.code === get(item, 'MobileMake.value')
      );
      const makeValue =
        get(make, 'code') === 'OTHERMAKE'
          ? get(item, 'MobileMakeOther')
          : get(make, 'value', get(item, 'MobileMake.value'));
      return `${get(device, 'value', get(item, 'DeviceType.value'))} | ${makeValue} | ${modelValue}`;
    }
    return null;
  });
};

export const schemeInformation = {
  [COVERMYTOOLS]: {
    name: 'Covermytools',
    url: process.env.REACT_APP_CMT_URL,
    logo: cmtLogo,
    claimUrl: 'https://www.covermytools.co.uk/claims',
    mtaUrl: (encryptedPolicyId, uid) => `${process.env.REACT_APP_CMT_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) => `${process.env.REACT_APP_CMT_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_CMT_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ffc600',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 198, 0, 0.04)',
    specialCategoriesQuote: {
      'Tools Excess': quoteState => `£${get(quoteState, 'displayValues.ToolsExcess')}`,
      'Total Sum Insured': quoteState => `£${get(quoteState, 'displayValues.ToolsSumInsured')}`
    },
    specialCategoriesPolicy: {
      'Tools Excess': policy => `£${get(policy, 'displayValues.ToolsExcess')}`,
      'Total Sum Insured': policy => `£${get(policy, 'displayValues.ToolsSumInsured')}`
    }
  },
  [COVERMYTOOLSINSURANCE]: {
    name: 'Covermytools',
    url: process.env.REACT_APP_CMT_URL,
    logo: cmtLogo,
    claimUrl: 'https://www.covermytools.co.uk/claims',
    mtaUrl: (encryptedPolicyId, uid) => `${process.env.REACT_APP_CMT_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) => `${process.env.REACT_APP_CMT_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_CMT_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ffc600',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 198, 0, 0.04)',
    specialCategoriesQuote: {
      'Tools Excess': quoteState => `£${get(quoteState, 'displayValues.ToolsExcess')}`,
      'Total Sum Insured': quoteState => `£${get(quoteState, 'displayValues.ToolsSumInsured')}`
    },
    specialCategoriesPolicy: {
      'Tools Excess': policy => `£${get(policy, 'displayValues.ToolsExcess')}`,
      'Total Sum Insured': policy => `£${get(policy, 'displayValues.ToolsSumInsured')}`
    }
  },
  [MOBIRU]: {
    name: 'Mobiru Gadget Insurance',
    url: 'https://insyncinsurance.formstack.com/forms/mobiru_make_a_change',
    logo: mobiruLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: () => `https://insyncinsurance.formstack.com/forms/mobiru_make_a_change`,
    quoteUrl: () => 'https://insyncinsurance.formstack.com/forms/mobiru_make_a_change',
    headerColor: 'linear-gradient(90deg, #32aee3 0%, #1d7b3c 189.66%)',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(50, 174, 227, 0.04)',
    specialCategoriesQuote: {
      'Devices covered': quoteState => mobiruDevicesCovered(quoteState)
    },
    specialCategoriesPolicy: {
      'Devices covered': policy => mobiruDevicesCovered(policy)
    }
  },
  [MOBIRU_BUSINESS]: {
    name: 'Mobiru Gadget Insurance',
    url: 'https://insyncinsurance.formstack.com/forms/mobiru_make_a_change',
    logo: mobiruLogo,
    mtaUrl: () => `https://insyncinsurance.formstack.com/forms/mobiru_make_a_change`,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    quoteUrl: () => 'https://insyncinsurance.formstack.com/forms/mobiru_make_a_change',
    headerColor: 'linear-gradient(90deg, #32aee3 0%, #1d7b3c 189.66%)',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(50, 174, 227, 0.04)',
    specialCategoriesQuote: {
      'Devices covered': quoteState => mobiruDevicesCovered(quoteState)
    },
    specialCategoriesPolicy: {
      'Devices covered': policy => mobiruDevicesCovered(policy)
    }
  },
  [BEAUTY]: {
    name: 'Insync Beauty',
    url: process.env.REACT_APP_BEAUTY_URL,
    logo: insyncLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) => `${process.env.REACT_APP_BEAUTY_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_BEAUTY_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_BEAUTY_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: ' #ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Limit of Indemnity': quoteState => get(quoteState, 'displayValues.LimitOfIndemnity.value'),
      'Stock and Equipment Cover': quoteState => get(quoteState, 'displayValues.SalonStockEquipmentCover.value'),
      'Teaching Cover': quoteState =>
        get(quoteState, 'displayValues.SalonTeachingCover') === 'true' ? 'Covered' : 'Not Covered'
    },
    specialCategoriesPolicy: {
      'Limit of Indemnity': policy => get(policy, 'displayValues.LimitOfIndemnity.value'),
      'Stock and Equipment Cover': policy => get(policy, 'displayValues.SalonStockEquipmentCover.value'),
      'Teaching Cover': policy =>
        get(policy, 'displayValues.SalonTeachingCover') === 'true' ? 'Covered' : 'Not Covered'
    }
  },
  [AXIS_CLINICAL]: {
    name: 'Insync Medical Insurance',
    url: process.env.REACT_APP_AXIS_CLINICAL_URL,
    logo: insyncLogo,
    claimUrl: 'https://insyncinsurance.formstack.com/forms/insync_medical_claims',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_CLINICAL_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_AXIS_CLINICAL_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_CLINICAL_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: ' #ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Limit of Indemnity': quoteState => get(quoteState, 'displayValues.LimitOfIndemnity.value'),
      Profession: quoteState => get(quoteState, 'displayValues.PractitionerProfessionClinical.value'),
      Premium: quoteState =>
        `£${(
          parseFloat(get(quoteState, 'price.grossPremiumAmountIncTax.amount')) +
          parseFloat(get(quoteState, 'price.fees.amount'))
        ).toFixed(2)}`
    },
    specialCategoriesPolicy: {
      'Limit of Indemnity': policy => get(policy, 'displayValues.LimitOfIndemnity.value'),
      Profession: policy => get(policy, 'displayValues.PractitionerProfessionClinical.value')
    }
  },
  [AXIS_AESTHETIC]: {
    name: 'Insync Aesthetics Insurance',
    url: process.env.REACT_APP_AXIS_AESTHETIC_URL,
    logo: insyncLogo,
    claimUrl: 'https://insyncinsurance.formstack.com/forms/axisaestheticclaim',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_AESTHETIC_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_AXIS_AESTHETIC_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_AESTHETIC_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Limit of Indemnity': quoteState => get(quoteState, 'displayValues.LimitOfIndemnity.value')
    },
    specialCategoriesPolicy: {
      'Limit of Indemnity': policy => get(policy, 'displayValues.LimitOfIndemnity.value')
    }
  },
  [PBIS]: {
    name: 'Paul Baker Insurance',
    url: process.env.REACT_APP_INSYNC_PBIS_URL,
    logo: pbisLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_INSYNC_PBIS_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_INSYNC_PBIS_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_INSYNC_PBIS_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#2c7a59',
    headerLogo: pbisHeaderLogo,
    headerStyle: css`
      height: 2.5rem;
      @media (max-width: 767px) {
        display: none;
      }
      border-radius: 100%;
    `,
    specificInfoColor: '#f5fbf8',
    specialCategoriesQuote: {
      'Home Type': quoteState =>
        get(quoteState, 'genericInsuredObject[0].dataPointData.PBISMainResidence.code') === 'PARK'
          ? 'PARK HOME'
          : 'LEISURE HOME',
      'Home Make': policy => {
        const genericInsuredObject = get(policy, 'genericInsuredObject').find(
          item => item.dataPointType === 'GENERIC_INSURED_OBJECT'
        );

        const makeCode =
          get(genericInsuredObject, 'dataPointData.PBISMakeParkHome.code') ||
          get(genericInsuredObject, 'dataPointData.PBISMakeLeisureHome.code');

        return find(lookupStore.pbisParkHomeMake, element => element.code === makeCode)?.value || 'Unspecified';
      },
      'Home Model': quoteState => {
        const genericInsuredObject = get(quoteState, 'genericInsuredObject').find(
          item => item.dataPointType === 'GENERIC_INSURED_OBJECT'
        );
        return get(genericInsuredObject, 'dataPointData.PBISModel') || 'Unspecified';
      }
    },
    specialCategoriesPolicy: {
      'Home Type': policy => (get(policy, 'PBISMainResidence.code') === 'PARK' ? 'Park Home' : 'Leisure Home'),
      'Home Make': policy => {
        const genericInsuredObject = get(policy, 'genericInsuredObject').find(
          item => item.dataPointType === 'GENERIC_INSURED_OBJECT'
        );

        const makeCode =
          get(genericInsuredObject, 'dataPointData.PBISMakeParkHome.code') ||
          get(genericInsuredObject, 'dataPointData.PBISMakeLeisureHome.code');

        return find(lookupStore.pbisParkHomeMake, element => element.code === makeCode)?.value || 'Unspecified';
      },
      'Home Model': policy => {
        const genericInsuredObject = get(policy, 'genericInsuredObject').find(
          item => item.dataPointType === 'GENERIC_INSURED_OBJECT'
        );
        return get(genericInsuredObject, 'dataPointData.PBISModel') || 'Unspecified';
      }
    }
  },
  [BEAZLEYAESTHETICS]: {
    name: 'Beazley Aesthetics',
    url: 'https://insyncinsurance.formstack.com/forms/beazleyaestheticchange',
    logo: insyncLogo,
    claimUrl: 'https://insyncinsurance.formstack.com/forms/beazleyaestheticclaim',
    mtaUrl: () => 'https://insyncinsurance.formstack.com/forms/beazleyaestheticchange',
    quoteUrl: () => 'https://insyncinsurance.formstack.com/forms/beazleyaestheticchange',
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Limit of Indemnity': quoteState => `£${get(quoteState, 'displayValues.LimitOfIndemnity')}`,
      'Teaching Cover': quoteState =>
        get(quoteState, 'displayValues.TeachingCover') === 'true' ? 'Covered' : 'Not Covered'
    },
    specialCategoriesPolicy: {
      'Limit of Indemnity': policy => `£${get(policy, 'displayValues.LimitOfIndemnity')}`,
      'Teaching Cover': policy => (get(policy, 'displayValues.TeachingCover') === 'true' ? 'Covered' : 'Not Covered')
    }
  },
  [COVEATRADESMAN]: {
    name: 'Covea Trades and Professions',
    url: process.env.REACT_APP_COVEA_TRADESMAN_URL,
    logo: coveaLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_COVEA_TRADESMAN_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_COVEA_TRADESMAN_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_COVEA_TRADESMAN_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Limit of Indemnity': quoteState => `${get(quoteState, 'displayValues.CoveaIndemnityLimit.value')}`,
      'Main Trade': quoteState => get(quoteState, 'displayValues.MainTrade.value')
    },
    specialCategoriesPolicy: {
      'Limit of Indemnity': policy => `${get(policy, 'displayValues.CoveaIndemnityLimit.value')}`,
      'Main Trade': policy => get(policy, 'displayValues.MainTrade.value')
    }
  },
  [AXAPI]: {
    name: 'AXA Professional',
    url: process.env.REACT_APP_AXA_PI_URL,
    logo: insyncLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) => `${process.env.REACT_APP_AXA_PI_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_AXA_PI_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXA_PI_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Main Trade': quoteState => get(quoteState, 'displayValues.TradeList.value'),
      'Limit of Indemnity': quoteState => `${get(quoteState, 'displayValues.LimitofIndemnity.value')}`
    },
    specialCategoriesPolicy: {
      'Main Trade': policy => get(policy, 'displayValues.TradeList.value'),
      'Limit of Indemnity': policy => `${get(policy, 'displayValues.LimitofIndemnity.value')}`
    }
  },
  [AXIS_PROPERTY_OWNERS]: {
    name: 'AXIS Property Owners',
    url: process.env.REACT_APP_AXIS_PROPERTY_OWNERS_URL,
    logo: insyncLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_PROPERTY_OWNERS_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_AXIS_PROPERTY_OWNERS_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_PROPERTY_OWNERS_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Type of Property': quoteState => `${get(quoteState, 'displayValues').map(val => val.typeOfProperty?.value)}`,
      'Limit of Indemnity': quoteState => `${get(quoteState, 'displayValues').map(val => val.limitOfIndemnity?.value)}`
    },
    specialCategoriesPolicy: {
      'Type of Property': policy => `${get(policy, 'displayValues').map(val => val.typeOfProperty?.value)}`,
      'Limit of Indemnity': policy => `${get(policy, 'displayValues').map(val => val.limitOfIndemnity?.value)}`
    }
  },
  [ALTEA]: {
    name: 'Altea',
    url: process.env.REACT_APP_STANDALONE_URL,
    logo: insyncLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_STANDALONE_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_STANDALONE_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_STANDALONE_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {},
    specialCategoriesPolicy: {}
  },
  [FARADAYLIABILITY]: {
    name: 'Faraday Liability',
    url: process.env.REACT_APP_FARADAY_LIABILITY_URL,
    logo: faradayLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_FARADAY_LIABILITY_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_FARADAY_LIABILITY_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_FARADAY_LIABILITY_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Main Trade': quoteState => get(quoteState, 'displayValues.MainTrade.value'),
      'Limit of Indemnity': quoteState => `${get(quoteState, 'displayValues.PublicLiabilityLOI.value')}`
    },
    specialCategoriesPolicy: {
      'Main Trade': policy => get(policy, 'displayValues.MainTrade.value'),
      'Limit of Indemnity': policy => `${get(policy, 'displayValues.PublicLiabilityLOI.value')}`
    }
  },
  [AXISPI]: {
    name: 'AXIS Professionals Combined',
    url: process.env.REACT_APP_AXIS_PI_URL,
    logo: insyncLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_PI_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_AXIS_PI_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_PI_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Main Trade': quoteState => get(quoteState, 'displayValues.TradeList.value'),
      'Limit of Indemnity': quoteState => `${get(quoteState, 'displayValues.LimitofIndemnity.value')}`
    },
    specialCategoriesPolicy: {
      'Main Trade': policy => get(policy, 'displayValues.TradeList.value'),
      'Limit of Indemnity': policy => `${get(policy, 'displayValues.LimitofIndemnity.value')}`
    }
  },
  [AXISSPI]: {
    name: 'AXIS Professionals Combined',
    url: process.env.REACT_APP_AXIS_PI_URL,
    logo: insyncLogo,
    claimUrl: 'https://www.claimbrain.co.uk/login',
    mtaUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_PI_URL}/policy/${encryptedPolicyId}/userId/${uid}`,
    quoteUrl: (encryptedQuoteRef, uid) =>
      `${process.env.REACT_APP_AXIS_PI_URL}/quote/${encryptedQuoteRef}/userId/${uid}`,
    renewalUrl: (encryptedPolicyId, uid) =>
      `${process.env.REACT_APP_AXIS_PI_URL}/renewal/${encryptedPolicyId}/userId/${uid}`,
    headerColor: '#ff7300',
    headerLogo: insyncHeaderLogo,
    specificInfoColor: 'rgba(255, 115, 0, 0.04)',
    specialCategoriesQuote: {
      'Main Trade': quoteState => get(quoteState, 'displayValues.TradeList.value'),
      'Limit of Indemnity': quoteState => `${get(quoteState, 'displayValues.LimitofIndemnity.value')}`
    },
    specialCategoriesPolicy: {
      'Main Trade': policy => get(policy, 'displayValues.TradeList.value'),
      'Limit of Indemnity': policy => `${get(policy, 'displayValues.LimitofIndemnity.value')}`
    }
  }
};
